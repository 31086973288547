<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <div class="px-8 py-3 my-8 bg-white rounded">
          <h4 class="font-subtitle-2 text-subtitle-1 my-6">Create New group</h4>
          <InputTextBox
            placeholder="Group name"
            @input="val => processValue(val,'group_name')"
            :warning="warning.group_name"
            warningClass="text-red-500"
          ></InputTextBox>
          <h5 class="font-subtitle-2 text-subtitle-1 mt-6 mb-1 text-gray-600"><small>Show on home page</small></h5>
          <InputDropdown
            :options="['yes', 'no']"
            placeholder="Show on home page"
            @input="val => processValue(val,'show_at_home')"
            :warning="warning.show_at_home"
            warningClass="text-red-500"
            ></InputDropdown>
          <h5 class="font-subtitle-2 text-subtitle-1 mt-6 mb-1 text-gray-600"><small>Add research(s) in group (optional)</small></h5>
          <InputTextBox
            placeholder="Research title"
            @input="val => processValue(val,'article_title')"
            :warning="warning.article_title"
            warningClass="text-red-500"
          ></InputTextBox>

          <div class="my-2 w-full" v-if="GET_ARTICLES.length>0">
            <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Click to add research below.</small></h5>
            <div class="grid grid-cols-3 gap-4">
              <div class="bg-background-50 rounded shadow p-3" v-show="!articleIncluded(item.id)" v-for="(item,index) in GET_ARTICLES" v-bind:key="item.id"  @click="pushArticle(index)">
                <small>{{ item.article_title }}</small>
                <hr class="border-gray-200" />
                <small class="text-gray-500 mt-2">Published at: {{ moment(item.published_at).format('DD/MM/YYYY') }}</small><br>
                <!-- <small class="text-gray-500 mt-2">created at: {{ moment(item.created_at).format('DD/MM/YYYY') }}</small> -->
                </div>
            </div>
          </div>
          <hr class="border-gray-200 my-3" />
          <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Selected reserach: </small></h5>
          <div class="my-2 w-full" v-if="form.articles.length>0">
            <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Click to remove reserach below.</small></h5>
            <div class="grid grid-cols-3 gap-4">
              <div class="bg-primary rounded shadow p-3 text-white" v-for="(item,indexExisting) in form.articles" v-bind:key="item.id" @click="removeArticle(indexExisting)">
                <small>{{ item.article_title }}</small>
                <hr class="border-gray-200" />
                <small class="text-gray-500 mt-2">Published at: {{ moment(item.published_at).format('DD/MM/YYYY') }}</small><br>
                <!-- <small class="text-gray-500 mt-2">created at: {{ moment(item.created_at).format('DD/MM/YYYY') }}</small> -->
              </div>
            </div>
          </div>
          <div class="my-2 w-full">
            <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>{{ this.form.articles.length }} research selected.</small></h5>
          </div>

          <div class="my-12 w-full">
              <Loading :show="!ARTICLE_GROUP_READY" />
              <Alert relative normal v-show="storeError">Failed to create new group.</Alert>
          </div>

          <div class="my-12 w-full">
            <ButtonSolidBox class="px-12" @click="submitForm()">
              {{ getSubmitButtonLabel() }}
            </ButtonSolidBox>
          </div>
          <Modal name="ArticleGroup" :show.sync="showModal"  strict-close>
            <div class="flex flex-col align-items-center w-full p-12">
              <div class="mx-auto my-8 text-center">
                <svg
                  class="block mx-auto"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                    fill="white"
                    fill-opacity="0.12"
                  />
                  <path
                    d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                    fill="#48D5D1"
                    fill-opacity="0.12"
                  />
                  <path
                    d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                    stroke="#48D5D1"
                    stroke-width="1.5"
                  />
                  <path
                    d="M46.334 26.0133L28.706 43.6414L17.666 32.6014L23.028 27.2395L28.706 32.9174L32 29.6234L40.972 20.6514L46.334 26.0133Z"
                    fill="#48D5D1"
                  />
                </svg>
                <div class="block mt-12 font-headline-6 text-headline-6 text-gray">
                  Group has been saved!
                </div>
                <div
                  class="block font-body-1 text-body-1 text-gray-500 max-w-sm mb-5"
                >
                  Group has been saved successfully.
                </div>
                <ButtonSolidBox v-show="ARTICLE_READY" class="px-8" @click="closeModal()">
                  Ok
                </ButtonSolidBox>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
    <!-- <div class="w-104 min-w-0 bg-white" style="min-width:395px;max-width:395px">
      <div class="px-12 mx-auto mt-12 flex justify-between items-center">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="font-headline-6 text-headline-6">Article categories</h3>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<style scoped>
 </style>

<script>
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import InputDropdown from '@/components/Inputs/InputDropdown.vue'
// import InputImage from '@/components/Inputs/InputImage.vue'
// import InputAdvancedTextArea from '@/components/Inputs/InputAdvancedTextArea.vue'
import Alert from '@/components/Alert.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Modal from '@/components/Modal.vue'
import Loading from '@/components/Loading.vue'
import { createNamespacedHelpers } from 'vuex'

const category = createNamespacedHelpers('category')
const article = createNamespacedHelpers('article')
const articleGroup = createNamespacedHelpers('articleGroup')

export default {
  components: {
    InputTextBox,
    InputDropdown,
    // InputImage,
    Alert,
    Modal,
    Loading,
    // InputAdvancedTextArea,
    ButtonSolidBox,
    TheContentTopNavigationBar: TheContentTopNavigationBar,
    // TheSideCalendarBar: TheSideCalendarBar,
    TheSideNavigationBar: TheSideNavigationBar
  },
  data () {
    return {
      showModal: false,
      storeError: false,
      queue: [],
      fetching: false,
      form: {
        id: '',
        group_name: '',
        show_at_home: '',
        articles: []
      },
      warning: {
        group_name: '',
        show_at_home: ''
      }
    }
  },
  watch: {
    'form.first_content': function (newVal, oldVal) {
    // console.log(newVal)
    }
  },
  computed: {
    ...category.mapGetters(['SELECT_CATEGORY']),
    ...article.mapGetters(['ARTICLE_READY', 'GET_ARTICLES']),
    ...articleGroup.mapGetters(['ARTICLE_GROUP_READY'])
  },
  methods: {
    ...category.mapActions(['getCategories']),
    ...article.mapActions(['findArticles', 'createInit']),
    ...articleGroup.mapActions(['addGroup', 'createGroupInit']),
    getSubmitButtonLabel () {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, 'slug') &&
        `${this.$route.params.slug}`.length > 0
      ) {
        return 'Update'
      }
      return 'Save'
    },
    pushArticle (index) {
      if (!this.articleIncluded(this.GET_ARTICLES[index].id)) {
        this.form.articles.push(this.GET_ARTICLES[index])
      }
    },
    removeArticle (index) {
      this.form.articles.splice(index, 1)
    },
    articleIncluded (id) {
      for (var x = 0; x < this.form.articles.length; x++) {
        if (this.form.articles[x].id === id) {
          return true
        }
      }
      return false
    },
    fileAdded (file) {
      this.form.thumbnail = file
      this.warning.thumbnail = ''
    },
    fileRemoved () {
      this.form.thumbnail = ''
      this.warning.thumbnail = 'This field is required'
    },
    processValue (value, target) {
      if (value === '') {
        this.warning[target] = 'This field is required'
        this.form[target] = ''
      } else {
        if (this.warning[target] !== '') {
          this.warning[target] = ''
        }
        if (target === 'article_title' && this.ARTICLE_GROUP_READY && value.length > 3) {
          // console.log('find article with key: ' + value)
          if (this.fetching) {
            if (this.queue.length > 0) {
              this.queue[0] = { value: value, target: target }
            } else {
              this.queue.push({ value: value, target: target })
            }
            return
          }
          this.fetching = true
          this.findArticles({ data: { find: value } }).then(() => {
            this.fetching = false
            if (this.queue.length > 0) {
              this.processValue(this.queue[0].value, this.queue[0].target)
              this.queue.pop()
            }
          })
          return
        }
        this.form[target] = value
      }
    },
    validate () {
      var validation = true
      if (this.form.group_name === '') {
        validation = false
        this.warning.group_name = 'This field is required'
      }
      return validation
    },
    submitForm () {
      if (this.validate()) {
        this.commit()
      }
    },
    commit () {
      this.storeError = false
      // console.log('store data')
      // var formData = new FormData()
      // Object.keys(this.form).forEach(val => {
      //   formData.append(val, this.form[val])
      // })
      this.addGroup({ form: this.form }).then((data) => {
        if (data.data !== null) {
          this.form.id = data.data.id
          // this.form = data.data
          this.showModal = true
        }
      }).catch(err => {
      // console.log(err)
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    closeModal () {
      this.showModal = false
      this.showDetail(this.form.id)
    },
    showDetail (id) {
      this.$router.push({ name: 'route.dashboard.articlegroup.editor.existing', params: { id: id } })
    }
  },
  created () {
    this.createGroupInit()
    // this.getCategories({ data: null })
  }
}
</script>
