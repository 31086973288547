<template>
  <div class="w-full px-8">
    <div class="container my-11 mx-auto bg-background-50">
      <div class="flex flex-col px-12 py-14" v-if="TRANSLATED_ARTICLE_READY">
        <div class="block">
          Editor for: {{ lang }}
        </div>
          <div class="block">
          <span class="font-subtitle-2 text-subtitle-2 inline-block">
            {{ article.writter !== undefined? article.writter.fullname : "" }}
          </span>
          <span
            class="font-subtitle-1 text-subtitle-2 inline-block pl-1 pb-3 text-gray-500"
          >
            - <small>created at: </small> {{ getDate(article.created_at) }}
          </span>
          <ButtonSolidBox class="px-4 py-1 ml-4 rounded-full" @click="toggleEdit()">
          {{ btnEditLabel }}
          </ButtonSolidBox>
        </div>
        <div class="block">
          <div style="max-width: 300px;">
            <InputDropdown
              :options="['DRAFT', 'PUBLISHED']"
              placeholder="Publication status"
              :value="isNotEmpty('status') ? GET_TRANSLATED_ARTICLE.status : 'DRAFT'"
              @input="val => statusChanged(val)"
              :disabled="editMode"
            ></InputDropdown>
          </div>
        </div>
        <h4
          v-show="!editMode"
          class="font-headline-5 text-headline-5 title-font text-gray-900 mt-4 mb-1"
        >
          {{ isNotEmpty('article_title') ? GET_TRANSLATED_ARTICLE.article_title : article.article_title}}
        </h4>
        <InputTextBox
          v-show="editMode"
          placeholder="Article title"
          :value="isNotEmpty('article_title') ? GET_TRANSLATED_ARTICLE.article_title : article.article_title"
          @input="val => processValue(val,'article_title')"
          :warning="warning.article_title"
          warningClass="text-red-500"
        ></InputTextBox>
        <div class="mt-16 font-subtitle-2 text-subtitle-1">Existing image Thumbnail</div>
        <div class="container py-8 w-full">
          <img width="600" height="340" style="object-fit:contain !important;max-width:600px;max-height:340px;" :src="getImage(isNotEmpty('thumbnail') ? GET_TRANSLATED_ARTICLE.thumbnail : article.thumbnail)" />
        </div>

        <div class="mt-16 font-subtitle-2 text-subtitle-1" v-show="editMode">New image Thumbnail</div>
        <div class="py-3 border border-dark p-4 rounded" v-show="editMode">
          <div class="inline-block w-1/2 align-top">
            <p class="font-body-2 text-body-2 text-gray-500">
              Maximum upload file size, 2 MB Format image: JPG, JPEG, PNG.<br />
              Recommended image ratio: <br/>- 640px * 320px <br/>- 1024px * 768px
            </p>
          </div>
          <InputImage class="inline-block w-1/2"
            @file-added="(file) => fileAdded(file)"
            @file-removed="() => fileRemoved()"
            warningClass="text-red-500"
          />
          <ButtonSolidBox class="px-12" v-show="hasNewThumbnail()" @click="uploadNewThumbnail()">
            Update thumbnail
          </ButtonSolidBox>
        </div>

        <p class="font-body-1 text-body-1 py-4 text-gray-500">
          <small>Summary</small>
        </p>
        <p v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray">
          {{ isNotEmpty('first_summary') ? GET_TRANSLATED_ARTICLE.first_summary : article.first_summary }}
        </p>
        <InputTextBox
          v-show="editMode"
          placeholder="Keypoint 1"
          :value="isNotEmpty('first_summary') ? GET_TRANSLATED_ARTICLE.first_summary : article.first_summary"
          @input="val => processValue(val,'first_summary')"
          :warning="warning.first_summary"
          warningClass="text-red-500"
        ></InputTextBox>
        <p v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray">
          {{ isNotEmpty('second_summary') ? GET_TRANSLATED_ARTICLE.second_summary : article.second_summary }}
        </p>
        <InputTextBox
          v-show="editMode"
          placeholder="Keypoint 2"
          :value="isNotEmpty('second_summary') ? GET_TRANSLATED_ARTICLE.second_summary : article.second_summary"
          @input="val => processValue(val,'second_summary')"
          :warning="warning.second_summary"
          warningClass="text-red-500"
        ></InputTextBox>
        <p v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray">
          {{ isNotEmpty('third_summary') ? GET_TRANSLATED_ARTICLE.third_summary : article.third_summary }}
        </p>
        <InputTextBox
          v-show="editMode"
          placeholder="Keypoint 3"
          :value="isNotEmpty('third_summary') ? GET_TRANSLATED_ARTICLE.third_summary : article.third_summary"
          @input="val => processValue(val,'third_summary')"
          :warning="warning.third_summary"
          warningClass="text-red-500"
        ></InputTextBox>
        <p class="font-body-1 text-body-1 pt-4 text-gray-500">
          <small>Content</small>
        </p>
        <p class="font-body-1 text-body-1 pb-4 text-red-500 mb-2" v-show="editMode">
          <small>{{ warning.short_description }}</small>
        </p>
        <div v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray" v-html="isNotEmpty('first_content') ? GET_TRANSLATED_ARTICLE.first_content : article.first_content"></div>
        <InputAdvancedTextArea
          v-show="editMode"
          placeholder="Type something..."
          @content="val => processValue(val,'first_content')"
          @text-only="val => processValue(val, 'short_description')"
          :warning="warning.first_content"
          refId="firstContentEditor"
          warningClass="text-red-500"
          :value="isNotEmpty('first_content') ? GET_TRANSLATED_ARTICLE.first_content : article.first_content"
        />
        <div v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray" v-html="isNotEmpty('second_content') ? GET_TRANSLATED_ARTICLE.second_content : article.second_content"></div>
        <InputAdvancedTextArea
          v-show="editMode"
          placeholder="Type something..."
          @content="val => processValue(val,'second_content')"
          :warning="warning.second_content"
          :value="isNotEmpty('second_content') ? GET_TRANSLATED_ARTICLE.second_content : article.second_content"
          warningClass="text-red-500"
        />
      </div>
      <div class="mt-1 w-full px-12 pb-5">
        <Loading :show="!TRANSLATED_ARTICLE_READY" />
        <Alert relative normal v-show="storeError">Failed to update translated article.</Alert>
        <ButtonSolidBox class="px-12" v-show="editMode && TRANSLATED_ARTICLE_READY" @click="submitForm()">
          Save
        </ButtonSolidBox>
      </div>
    </div>
    <Modal name="Article" :show.sync="showModal"  strict-close>
      <div class="flex flex-col align-items-center w-full p-12">
        <div class="mx-auto my-8 text-center">
          <svg
            class="block mx-auto"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
              fill="white"
              fill-opacity="0.12"
            />
            <path
              d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
              fill="#48D5D1"
              fill-opacity="0.12"
            />
            <path
              d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
              stroke="#48D5D1"
              stroke-width="1.5"
            />
            <path
              d="M46.334 26.0133L28.706 43.6414L17.666 32.6014L23.028 27.2395L28.706 32.9174L32 29.6234L40.972 20.6514L46.334 26.0133Z"
              fill="#48D5D1"
            />
          </svg>
          <div class="block mt-12 font-headline-6 text-headline-6 text-gray">
            Translated article has been updated!
          </div>
          <div
            class="block font-body-1 text-body-1 text-gray-500 max-w-sm mb-5"
          >
            Translated article has been updated successfully.
          </div>
          <ButtonSolidBox v-show="TRANSLATED_ARTICLE_READY" class="px-8" @click="closeModal()">
            Ok
          </ButtonSolidBox>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import InputImage from '@/components/Inputs/InputImage.vue'
import InputAdvancedTextArea from '@/components/Inputs/InputAdvancedTextArea.vue'
import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Modal from '@/components/Modal.vue'
import InputDropdown from '@/components/Inputs/InputDropdown.vue'
import { createNamespacedHelpers } from 'vuex'
// import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
// import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'

const translatedArticle = createNamespacedHelpers('translatedArticle')
const category = createNamespacedHelpers('category')

export default {
  components: {
    ButtonSolidBox,
    // TheSideNavigationBar,
    // TheContentTopNavigationBar,
    InputDropdown,
    InputTextBox,
    Loading,
    Alert,
    Modal,
    InputImage,
    InputAdvancedTextArea
  },
  props: {
    lang: {
      type: String,
      default: 'ID'
    },
    article: null
  },
  data () {
    return {
      showModal: false,
      btnEditLabel: 'Edit',
      editMode: false,
      storeError: false,
      warning: {
        article_title: '',
        // publish_date: '',
        // thumbnail: '',
        first_summary: '',
        second_summary: '',
        third_summary: '',
        short_description: '',
        first_content: '',
        second_content: '',
        status: ''
      },
      form: {
        thumbnail: ''
      }
    }
  },
  computed: {
    ...translatedArticle.mapGetters(['TRANSLATED_ARTICLE_READY', 'GET_TRANSLATED_ARTICLE'])
  },
  methods: {
    ...translatedArticle.mapActions(['getTranslatedArticle', 'updateTranslatedArticle', 'updateTranslatedArticleStatus', 'updateTranslatedThumbnail']),
    ...category.mapActions(['getAllCategories']),
    isNotEmpty (propertyTag) {
      return this.GET_TRANSLATED_ARTICLE !== null && this.GET_TRANSLATED_ARTICLE[propertyTag] !== undefined && this.GET_TRANSLATED_ARTICLE[propertyTag] !== null && this.GET_TRANSLATED_ARTICLE[propertyTag] !== ''
    },
    getDate (dateString) {
      return this.moment(dateString).format('D/MM/YYYY')
    },
    statusChanged (val) {
      this.updateTranslatedArticleStatus({ lang: this.lang, originId: this.article.id, form: { status: val } }).then((data) => {
        if (data.data !== null) {
          console.log('result')
          console.log(data.data)
          // this.toggleEdit()
          this.showModal = true
        }
      }).catch(err => {
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    fileAdded (file) {
      this.form.thumbnail = file
      // this.warning.thumbnail = ''
    },
    fileRemoved () {
      this.form.thumbnail = ''
      // this.warning.thumbnail = 'This field is required'
    },
    getImage (path) {
      // var newPath = path.replace('public/', '')
      return process.env.VUE_APP_IMAGE_URL + '/' + path
    },
    toggleEdit () {
      this.editMode = !this.editMode
      this.btnEditLabel = this.editMode ? 'Cancel' : 'Edit'
      if (!this.editMode) {
        this.getTranslatedArticle({ lang: this.lang, originId: this.article.id })
      }
    },
    processValue (value, target) {
      if (target === 'short_description') {
        if (value.trim().length === 0) {
          this.GET_TRANSLATED_ARTICLE[target] = ''
          this.warning[target] = 'Please input some text in first content.'
          return
        } else {
          this.warning[target] = ''
          var trimmedValue = value.trim()
          this.GET_TRANSLATED_ARTICLE[target] = trimmedValue.slice(0, 240)
          return
        }
      }
      if (value === '') {
        this.warning[target] = 'This field is required'
      } else {
        if (this.warning[target] !== '') {
          this.warning[target] = ''
        }
        if (target === 'article_category_id') {
          this.GET_TRANSLATED_ARTICLE[target] = value.id
        } else if (target === 'published_at') {
          this.GET_TRANSLATED_ARTICLE[target] = this.moment(value).format('YYYY-MM-DD')
        } else {
          this.GET_TRANSLATED_ARTICLE[target] = value
        }
      }
    },
    validate () {
      var validation = true
      Object.keys(this.warning).forEach(val => {
        if (this.GET_TRANSLATED_ARTICLE[val] === '') {
          validation = false
          this.warning[val] = 'This field is required'
        }
      })
      return validation
    },
    hasNewThumbnail () {
      return this.form.thumbnail !== ''
    },
    uploadNewThumbnail () {
      var formData = new FormData()
      formData.append('newThumbnail', this.form.thumbnail)
      formData.append('origin', this.article.id)
      formData.append('lang', this.lang)
      this.updateTranslatedThumbnail({ form: formData }).then((data) => {
        if (data.data !== null) {
          this.getTranslatedArticle({ lang: this.lang, originId: this.article.id })
        }
      })
    },
    submitForm () {
      if (this.validate()) {
        this.commit()
      }
    },
    commit () {
      this.storeError = false

      var payload = this.GET_TRANSLATED_ARTICLE

      this.updateTranslatedArticle({ lang: this.lang, originId: this.article.id, form: payload }).then((data) => {
        if (data.data !== null) {
          console.log('result')
          console.log(data.data)
          this.toggleEdit()
          this.showModal = true
        }
      }).catch(err => {
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    closeModal () {
      this.getTranslatedArticle({ lang: this.lang, originId: this.article.id })
      this.showModal = false
    }
  },
  created () {
    this.getTranslatedArticle({ lang: this.lang, originId: this.article.id })
    // this.getArticleById({ id: this.$route.params.id })
  }
}
</script>
<style scoped>
</style>
