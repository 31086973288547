<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <div class="px-8 py-3 my-8 bg-white rounded">
          <h4 class="font-subtitle-2 text-subtitle-1 my-6">Create New Research</h4>
          <InputTextBox
            placeholder="Research title"
            @input="val => processValue(val,'article_title')"
            :warning="warning.article_title"
            warningClass="text-red-500"
          ></InputTextBox>
          <div class="flex flex-row gap-x-8">
            <InputTextBox
              type="date"
              class="inline-block w-1/2 h-12"
              placeholder="Publish Date"
              :warning="warning.published_at"
              @input="val => processValue(val,'published_at')"
              warningClass="text-red-500"
            ></InputTextBox>
            <InputDropdown
              class="inline-block w-1/2 h-12"
              placeholder="Research category"
              label="article_category_name"
              :options="SELECT_CATEGORY"
              :warning="warning.article_category_id"
              warningClass="text-red-500"
              @input="val => processValue(val,'article_category_id')"
            ></InputDropdown>
            <!-- <ButtonSolidBox class="px-4 py-1 rounded" @click="toggleEdit()">
              add
            </ButtonSolidBox> -->
          </div>
          <div class="mt-16 font-subtitle-2 text-subtitle-1">Image Thumbnail</div>

          <div class="py-3">
            <div class="inline-block w-1/2 align-top">
              <p class="font-body-2 text-body-2 text-gray-500">
                Maximum upload file size, 2 MB Format image: JPG, JPEG, PNG.<br />
                Recommended image ratio: <br/>- 640px * 320px <br/>- 1024px * 768px
              </p>
            </div>
            <InputImage class="inline-block w-1/2"
              @file-added="(file) => fileAdded(file)"
              @file-removed="() => fileRemoved()"
              :warning="warning.thumbnail"
              warningClass="text-red-500"
            />
            <div class="pt-8 font-subtitle-2 text-subtitle-1">Summary</div>
            <p class="font-body-2 text-body-2 text-gray-500 mb-4">
              3 keypoint of the content
            </p>
            <InputTextBox
              placeholder="Keypoint 1"
              @input="val => processValue(val,'first_summary')"
              :warning="warning.first_summary"
              warningClass="text-red-500"
            ></InputTextBox>
            <InputTextBox
              placeholder="Keypoint 2"
              @input="val => processValue(val,'second_summary')"
              :warning="warning.second_summary"
              warningClass="text-red-500"
            ></InputTextBox>
            <InputTextBox
              placeholder="Keypoint 3"
              @input="val => processValue(val,'third_summary')"
              :warning="warning.third_summary"
              warningClass="text-red-500"
            ></InputTextBox>

            <div class="pt-8 font-subtitle-2 text-subtitle-1">First section of main content</div>
            <p class="font-body-2 text-body-2 text-gray-500">
              The word count for section 2 and 3 is 150 in total
            </p>
            <p class="font-body-2 text-body-2 text-red-500 mb-4">
              {{ warning.short_description }}
            </p>
            <InputAdvancedTextArea
              placeholder="Type something..."
              @content="val => processValue(val,'first_content')"
              @text-only="val => processValue(val, 'short_description')"
              :warning="warning.first_content"
              refId="firstContentEditor"
              warningClass="text-red-500"
            />

            <div class="pt-8 font-subtitle-2 text-subtitle-1">Second section of main content</div>
            <p class="font-body-2 text-body-2 text-gray-500 mb-4">
              The word count for section 2 and 3 is 150 in total
            </p>
            <InputAdvancedTextArea
              placeholder="Type something..."
              @content="val => processValue(val,'second_content')"
              :warning="warning.second_content"
              warningClass="text-red-500"
            />

            <div class="my-12 w-full">
              <Loading :show="!ARTICLE_READY" />
              <Alert relative normal v-show="storeError">Failed to create new article.</Alert>
          </div>

            <div class="my-12 w-full">
              <ButtonSolidBox class="px-12" @click="submitForm()">
                {{ getSubmitButtonLabel() }}
              </ButtonSolidBox>
            </div>
          </div>
          <Modal name="Article" :show.sync="showModal"  strict-close>
            <div class="flex flex-col align-items-center w-full p-12">
              <div class="mx-auto my-8 text-center">
                <svg
                  class="block mx-auto"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                    fill="white"
                    fill-opacity="0.12"
                  />
                  <path
                    d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                    fill="#48D5D1"
                    fill-opacity="0.12"
                  />
                  <path
                    d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                    stroke="#48D5D1"
                    stroke-width="1.5"
                  />
                  <path
                    d="M46.334 26.0133L28.706 43.6414L17.666 32.6014L23.028 27.2395L28.706 32.9174L32 29.6234L40.972 20.6514L46.334 26.0133Z"
                    fill="#48D5D1"
                  />
                </svg>
                <div class="block mt-12 font-headline-6 text-headline-6 text-gray">
                  Article has been saved!
                </div>
                <div
                  class="block font-body-1 text-body-1 text-gray-500 max-w-sm mb-5"
                >
                  Article has been saved successfully.
                </div>
                <ButtonSolidBox v-show="ARTICLE_READY" class="px-8" @click="closeModal()">
                  Ok
                </ButtonSolidBox>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
    <!-- <div class="w-104 min-w-0 bg-white" style="min-width:395px;max-width:395px">
      <div class="px-12 mx-auto mt-12 flex justify-between items-center">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="font-headline-6 text-headline-6">Article categories</h3>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<style scoped>
 </style>

<script>
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import InputDropdown from '@/components/Inputs/InputDropdown.vue'
import InputImage from '@/components/Inputs/InputImage.vue'
import InputAdvancedTextArea from '@/components/Inputs/InputAdvancedTextArea.vue'
import Alert from '@/components/Alert.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Modal from '@/components/Modal.vue'
import Loading from '@/components/Loading.vue'
import { createNamespacedHelpers } from 'vuex'

const category = createNamespacedHelpers('category')
const article = createNamespacedHelpers('article')

export default {
  components: {
    InputTextBox,
    InputDropdown,
    InputImage,
    Alert,
    Modal,
    Loading,
    InputAdvancedTextArea,
    ButtonSolidBox,
    TheContentTopNavigationBar: TheContentTopNavigationBar,
    // TheSideCalendarBar: TheSideCalendarBar,
    TheSideNavigationBar: TheSideNavigationBar
  },
  data () {
    return {
      showModal: false,
      storeError: false,
      form: {
        article_title: '',
        thumbnail: '',
        published_at: '',
        article_category_id: '',
        first_summary: '',
        second_summary: '',
        third_summary: '',
        short_description: '',
        first_content: '',
        second_content: ''
      },
      warning: {
        article_title: '',
        published_at: '',
        thumbnail: '',
        article_category_id: '',
        first_summary: '',
        second_summary: '',
        third_summary: '',
        short_description: '',
        first_content: '',
        second_content: ''
      }
    }
  },
  watch: {
    'form.first_content': function (newVal, oldVal) {
    // console.log(newVal)
    }
  },
  computed: {
    ...category.mapGetters(['SELECT_CATEGORY']),
    ...article.mapGetters(['ARTICLE_READY'])
  },
  methods: {
    ...category.mapActions(['getAllCategories']),
    ...article.mapActions(['addArticle', 'createInit']),
    processTextOnly (val) {
      console.log(val)
    },
    getSubmitButtonLabel () {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, 'slug') &&
        `${this.$route.params.slug}`.length > 0
      ) {
        return 'Update'
      }
      return 'Save'
    },
    fileAdded (file) {
      this.form.thumbnail = file
      this.warning.thumbnail = ''
    },
    fileRemoved () {
      this.form.thumbnail = ''
      this.warning.thumbnail = 'This field is required'
    },
    processValue (value, target) {
      if (target === 'first_content') {
      // console.log(value)
      }
      if (target === 'short_description') {
        if (value.trim().length === 0) {
          this.form[target] = ''
          this.warning[target] = 'Please input some text in first content.'
          return
        } else {
          this.warning[target] = ''
          var trimmedValue = value.trim()
          this.form[target] = trimmedValue.slice(0, 240)
          return
        }
      }
      if (value === '') {
        this.warning[target] = 'This field is required'
      } else {
        if (this.warning[target] !== '') {
          this.warning[target] = ''
        }
        if (target === 'article_category_id') {
          this.form[target] = value.id
          return
        }
        if (target === 'published_at') {
          this.form[target] = this.moment(value).format('YYYY-MM-DD')
          return
        }
        this.form[target] = value
      }
    },
    validate () {
      var validation = true
      Object.keys(this.form).forEach(val => {
        if (this.form[val] === '') {
          validation = false
          this.warning[val] = 'This field is required'
        }
      })
      return validation
    },
    submitForm () {
      if (this.validate()) {
        this.commit()
      }
    },
    commit () {
      this.storeError = false
      var formData = new FormData()
      Object.keys(this.form).forEach(val => {
        formData.append(val, this.form[val])
      })
      this.addArticle({ form: formData }).then((data) => {
        if (data.data !== null) {
          this.form = data.data
          this.showModal = true
        }
      }).catch(err => {
      // console.log(err)
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    closeModal () {
      this.showModal = false
      this.showDetail(this.form.id)
    },
    showDetail (id) {
      this.$router.push({ name: 'route.dashboard.article.editor.existing', params: { id: id } })
    }
  },
  created () {
    this.createInit()
    this.getAllCategories()
  }
}
</script>
