<template>
<div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8 mt-10 tab-border">
        <button @click="activeTab='EN'" type="button" class="tab-button py-1 px-2 mx-1">English</button>
        <button v-if="ARTICLE_READY" @click="activeTab='ID'" type="button" class="tab-button py-1 px-2 mx-1" :disabled="isDeleting">Bahasa</button>
      </div>
      <translated-editor v-if="ARTICLE_READY" v-show="ARTICLE_READY && (activeTab=='ID')" lang="ID" :article="GET_ARTICLE" />
      <div v-show="activeTab=='EN'" class="w-full px-8">
        <div class="container my-11 mx-auto bg-background-50">
          <div class="flex flex-col px-12 py-14" v-if="ARTICLE_READY && !isDeleting">
            <div class="block">
              <span class="font-subtitle-2 text-subtitle-2 inline-block">
                {{ GET_ARTICLE.writter.fullname }}
              </span>
              <span
                class="font-subtitle-1 text-subtitle-2 inline-block pl-1 pb-3 text-gray-500"
              >
                - <small>created at: </small> {{ getDate(GET_ARTICLE.created_at) }}
              </span>
              <ButtonSolidBox class="px-4 py-1 ml-4 rounded-full" @click="toggleEdit()">
              {{ btnEditLabel }}
              </ButtonSolidBox>
            </div>
            <div class="block">
              <Loading :show="!analyticFetched" />
            </div>
            <div v-if="analyticFetched" class="block">
              <span
                class="font-subtitle-1 text-subtitle-2 inline-block pl-1 pb-3 text-gray-500"
              >
                <small>Viewer: </small> {{ analytic.article_viewer + analytic.guest_viewer }}
              </span>
              <span
                  class="font-subtitle-1 text-subtitle-2 inline-block pl-4 text-gray-500"
                  ><small>Total Viewer: </small>{{ (analytic.user_viewer + analytic.non_user_viewer) }}</span
                >
              <span
                  class="font-subtitle-1 text-subtitle-2 inline-block pl-4 text-gray-500"
                  ><small>Viewer (user): </small>{{ analytic.user_viewer }}</span
                >
              <span
                  class="font-subtitle-1 text-subtitle-2 inline-block pl-4 text-gray-500"
                  ><small>Viewer (nonuser): </small>{{ analytic.non_user_viewer }}</span
                >
              <span
                  class="font-subtitle-1 text-subtitle-2 inline-block pl-4 text-gray-500"
                  ><small>Viewer (sharedlink): </small>{{ analytic.sharedlink_viewer }}</span
                >
              <span
                class="font-subtitle-1 text-subtitle-2 inline-block pl-4 text-gray-500"
                ><small>Saved: </small>{{ analytic.bookmark }}</span
              >
            </div>
            <h4
              v-show="!editMode"
              class="font-headline-5 text-headline-5 title-font text-gray-900 mt-4 mb-1"
            >
              {{ GET_ARTICLE.article_title }}
            </h4>
            <InputTextBox
              v-show="editMode"
              placeholder="Article title"
              :value="GET_ARTICLE.article_title"
              @input="val => processValue(val,'article_title')"
              :warning="warning.article_title"
              warningClass="text-red-500"
            ></InputTextBox>
            <p v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray">
              <small>Publish: {{ moment(GET_ARTICLE.published_at).format('DD/MM/YYYY') }}</small>
            </p>
            <InputTextBox
              type="date"
              v-show="editMode"
              placeholder="Publish date"
              :value="GET_ARTICLE.published_at"
              @input="val => processValue(val,'published_at')"
              :warning="warning.published_at"
              warningClass="text-red-500"
            ></InputTextBox>
            <p v-show="!editMode" class="font-subtitle-1 text-subtitle-1 text-gray-500">
              {{ GET_ARTICLE.category.article_category_name }}
            </p>
            <InputDropdown
              v-show="editMode"
              class="inline-block w-full h-12"
              :placeholder="`Current category: ${GET_ARTICLE.category.article_category_name}`"
              label="article_category_name"
              :options="SELECT_CATEGORY"
              :warning="warning.article_category_id"
              warningClass="text-red-500"
              @input="val => processValue(val,'article_category_id')"
            ></InputDropdown>
            <div class="mt-16 font-subtitle-2 text-subtitle-1" v-show="!tagEditMode">
              Tags
              <ButtonSolidBox class="px-4 py-1 ml-3 rounded-full" @click="tagEditMode = true">
                edit
              </ButtonSolidBox>
            </div>
            <div class="mt-16 font-subtitle-2 text-subtitle-1" v-show="tagEditMode">
              Edit tags
              <ButtonSolidBox class="px-4 py-1 ml-3 rounded-full" @click="tagEditMode = false">
                done
              </ButtonSolidBox>
            </div>
            <div class="block pb-2 my-8" v-show="tagEditMode && GET_RESEARCH_TAGS.length < 5">
              <strong>Add non listed tag:</strong>
              <div class="inline-block w-50 ml-10">
                <input-text-box
                type="text"
                placeholder="Enter tag name"
                @input="val => addNewTagName(val)"
                hide-errors
                />
              </div>
              <div class="inline-flex">
                <ButtonSolidBox class="px-12 ml-10" @click="saveNewTag()">
                  Save
                </ButtonSolidBox>
              </div>
            </div>
            <div>
              <div>
                <div class="block pb-2 mx-3"><Loading :show="!RESEARCH_TAG_READY" /></div>
                <div class="inline-flex my-4 mx-2" v-for="data in GET_RESEARCH_TAGS" :key="'research-tag'+data.id">
                    <button v-if="data.related_tags !== null" type="button" class="my-auto rounded-full border px-3 py-2 shadow bg-gray-300" @click="deleteResearchTag(data.id)">
                      <strong>{{ data.related_tags.tag_name }}</strong>
                    </button>
                </div>
                <div class="inline-flex my-4 mx-3 border border-gray-500 rounded-full py-2 px-6 shadow" v-show="GET_RESEARCH_TAGS.length < 1 && RESEARCH_TAG_READY">
                  <span>No tag found, please add one or more tag.</span>
                </div>
              </div>
            </div>
            <div class="my-2"><hr></div>
            <div>
              <div class="block pb-2 mx-3"><Loading :show="!TAG_READY" /></div>
              <div class="inline-flex my-4 mx-2" v-for="data in GET_TAGS" :key="'tag'+data.id">
                  <button v-if="tagEditMode && !isTagIncluded(data.id) && GET_RESEARCH_TAGS.length < 5" type="button" class="my-auto rounded-full border px-3 py-2 shadow" @click="addTag(GET_ARTICLE.id, data.id)">
                    <strong>{{ data.tag_name }}</strong>
                  </button>
              </div>
              <div class="inline-flex my-4 mx-3 border border-gray-500 rounded-full py-2 px-6 shadow" v-show="GET_TAGS.length < 1 && TAG_READY">
                <span>No tag found, please add one or more tag to master data.</span>
              </div>
            </div>

            <div class="mt-16 font-subtitle-2 text-subtitle-1">Existing image Thumbnail</div>
            <div class="container py-8 w-full">
              <img width="600" height="340" style="object-fit:contain !important;max-width:600px;max-height:340px;" :src="getImage(GET_ARTICLE.thumbnail)" />
            </div>

            <div class="mt-16 font-subtitle-2 text-subtitle-1" v-show="editMode">New image Thumbnail</div>
            <div class="py-3 border border-dark p-4 rounded" v-show="editMode">
              <div class="inline-block w-1/2 align-top">
                <p class="font-body-2 text-body-2 text-gray-500">
                  Maximum upload file size, 2 MB Format image: JPG, JPEG, PNG.<br />
                  Recommended image ratio: <br/>- 640px * 320px <br/>- 1024px * 768px
                </p>
              </div>
              <InputImage class="inline-block w-1/2"
                @file-added="(file) => fileAdded(file)"
                @file-removed="() => fileRemoved()"
                warningClass="text-red-500"
              />
              <ButtonSolidBox class="px-12" v-show="hasNewThumbnail()" @click="uploadNewThumbnail()">
                Update thumbnail
              </ButtonSolidBox>
            </div>

            <p class="font-body-1 text-body-1 py-4 text-gray-500">
              <small>Summary</small>
            </p>
            <p v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray">
              {{ GET_ARTICLE.first_summary }}
            </p>
            <InputTextBox
              v-show="editMode"
              placeholder="Keypoint 1"
              :value="GET_ARTICLE.first_summary"
              @input="val => processValue(val,'first_summary')"
              :warning="warning.first_summary"
              warningClass="text-red-500"
            ></InputTextBox>
            <p v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray">
              {{ GET_ARTICLE.second_summary }}
            </p>
            <InputTextBox
              v-show="editMode"
              placeholder="Keypoint 1"
              :value="GET_ARTICLE.second_summary"
              @input="val => processValue(val,'second_summary')"
              :warning="warning.second_summary"
              warningClass="text-red-500"
            ></InputTextBox>
            <p v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray">
              {{ GET_ARTICLE.third_summary }}
            </p>
            <InputTextBox
              v-show="editMode"
              placeholder="Keypoint 1"
              :value="GET_ARTICLE.third_summary"
              @input="val => processValue(val,'third_summary')"
              :warning="warning.third_summary"
              warningClass="text-red-500"
            ></InputTextBox>
            <p class="font-body-1 text-body-1 pt-4 text-gray-500">
              <small>Content</small>
            </p>
            <p class="font-body-1 text-body-1 pb-4 text-red-500 mb-2" v-show="editMode">
              <small>{{ warning.short_description }}</small>
            </p>
            <div v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray ql-editor" v-html="GET_ARTICLE.first_content"></div>
            <InputAdvancedTextArea
              v-show="editMode"
              placeholder="Type something..."
              @content="val => processValue(val,'first_content')"
              @text-only="val => processValue(val, 'short_description')"
              :warning="warning.first_content"
              refId="firstContentEditor"
              warningClass="text-red-500"
              :value="GET_ARTICLE.first_content"
            />
            <div v-show="!editMode" class="font-body-1 text-body-1 py-1 text-gray ql-editor " v-html="GET_ARTICLE.second_content"></div>
            <InputAdvancedTextArea
              v-show="editMode"
              placeholder="Type something..."
              @content="val => processValue(val,'second_content')"
              :warning="warning.second_content"
              :value="GET_ARTICLE.second_content"
              warningClass="text-red-500"
            />
          </div>
          <div class="mt-1 w-full px-12 pb-5">
            <Loading :show="!ARTICLE_READY" />
            <Alert relative normal v-show="storeError">Failed to update article.</Alert>
            <ButtonSolidBox class="px-12" v-show="editMode && ARTICLE_READY" @click="submitForm()">
              Save
            </ButtonSolidBox>
          </div>
        </div>
        <div class="container mx-auto" v-show="ARTICLE_READY && !editMode">
          <hr>
          <div class="w-full px-8 mt-5 mb-10" v-show="isDeleting">
            <Loading :show="isDeleting" />
          </div>
          <div class="w-full px-8 mt-5 mb-10 text-right" v-show="!deleteMode">
            <button @click=" () => deleteMode = true" type="button" class="text-red border border-red px-4 py-2 rounded">
              <u>Delete Article</u>
            </button>
          </div>
          <div class="w-full px-8 mt-5 mb-10 text-right"  v-show="deleteMode && !isDeleting">
            <div class="block text-left">
              <label>Type <b>CONFIRM DELETE</b> to delete</label>
            </div>
            <InputTextBox
              v-if="deleteMode"
              placeholder="Type 'CONFIRM DELETE' to delete"
              @input="val => deleteConfirmation = val"
              :warning="warning.third_summary"
              warningClass="text-red-500"
            ></InputTextBox>
            <button @click="deleteArticle()" type="button" class="text-red border border-red px-4 py-2 rounded">
              <u>Confirm Delete</u>
            </button>
            <button @click="cancelDelete()" type="button" class="text-grey border border-grey ml-4 px-4 py-2 rounded">
              <u>Cancel</u>
            </button>
          </div>
        </div>
        <Modal name="Article" :show.sync="showModal"  strict-close>
          <div class="flex flex-col align-items-center w-full p-12">
            <div class="mx-auto my-8 text-center">
              <svg
                class="block mx-auto"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  fill="white"
                  fill-opacity="0.12"
                />
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  fill="#48D5D1"
                  fill-opacity="0.12"
                />
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  stroke="#48D5D1"
                  stroke-width="1.5"
                />
                <path
                  d="M46.334 26.0133L28.706 43.6414L17.666 32.6014L23.028 27.2395L28.706 32.9174L32 29.6234L40.972 20.6514L46.334 26.0133Z"
                  fill="#48D5D1"
                />
              </svg>
              <div class="block mt-12 font-headline-6 text-headline-6 text-gray">
                Article has been updated!
              </div>
              <div
                class="block font-body-1 text-body-1 text-gray-500 max-w-sm mb-5"
              >
                Article has been updated successfully.
              </div>
              <ButtonSolidBox v-show="ARTICLE_READY" class="px-8" @click="closeModal()">
                Ok
              </ButtonSolidBox>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<style scoped>

  .tab-button{
    border-left: 1px solid #707070;
    border-top: 1px solid #707070;
    border-right: 1px solid #707070;
    border-radius: 8px 8px 0 0;
  }
  .tab-border{
    border-bottom: 1px solid #707070;
  }</style>

<script>
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import InputDropdown from '@/components/Inputs/InputDropdown.vue'
import InputImage from '@/components/Inputs/InputImage.vue'
import InputAdvancedTextArea from '@/components/Inputs/InputAdvancedTextArea.vue'
import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Modal from '@/components/Modal.vue'
import { createNamespacedHelpers } from 'vuex'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import TranslatedEditor from '../../../components/ArticleEditors/TranslatedEditor.vue'
import request from '@/utils/axios'

const article = createNamespacedHelpers('article')
const category = createNamespacedHelpers('category')
const relatedTag = createNamespacedHelpers('relatedTag')
const researchTag = createNamespacedHelpers('researchTag')

export default {
  components: {
    ButtonSolidBox,
    TheSideNavigationBar,
    TheContentTopNavigationBar,
    InputTextBox,
    Loading,
    Alert,
    Modal,
    InputDropdown,
    InputImage,
    InputAdvancedTextArea,
    TranslatedEditor
  },
  data () {
    return {
      activeTab: 'EN',
      showModal: false,
      newTagName: '',
      btnEditLabel: 'Edit',
      editMode: false,
      tagEditMode: false,
      storeError: false,
      deleteConfirmation: '',
      deleteMode: false,
      isDeleting: false,
      warning: {
        article_title: '',
        // publish_date: '',
        // thumbnail: '',
        article_category_id: '',
        first_summary: '',
        second_summary: '',
        third_summary: '',
        short_description: '',
        first_content: '',
        second_content: '',
        published_at: ''
      },
      analyticFetched: false,
      analyticReady: true,
      analytic: {
        article_viewer: 0,
        bookmark: 0,
        guest_viewer: 0,
        non_user_viewer: 0,
        sharedlink_viewer: 0,
        user_viewer: 0
      },
      form: {
        thumbnail: ''
      }
    }
  },
  computed: {
    ...article.mapGetters(['ARTICLE_READY', 'GET_ARTICLE']),
    ...category.mapGetters(['SELECT_CATEGORY']),
    ...relatedTag.mapGetters(['GET_TAGS', 'TAG_READY', 'GET_DELETED_TAGS']),
    ...researchTag.mapGetters(['GET_RESEARCH_TAGS', 'RESEARCH_TAG_READY'])
  },
  methods: {
    ...article.mapActions(['getArticleById', 'updateArticle', 'updateThumbnail', 'softDeleteArticle']),
    ...relatedTag.mapActions(['getAllTags']),
    ...researchTag.mapActions(['getResearchTags', 'addNewTagByIdToResearch', 'addNewTagByNameToResearch', 'removeResearchTag']),
    ...category.mapActions(['getAllCategories']),
    getDate (dateString) {
      return this.moment(dateString).format('D/MM/YYYY')
    },
    addNewTagName (val) {
      this.newTagName = val
    },
    saveNewTag () {
      if (this.newTagName !== '') {
        this.addNewTagByNameToResearch({ researchId: this.$route.params.id, tagName: this.newTagName }).then(() => this.getResearchTags(this.$route.params.id))
      }
    },
    addTag (researchId, tagId) {
      this.addNewTagByIdToResearch({ researchId: researchId, tagId: tagId }).then(() => this.getResearchTags(this.$route.params.id))
    },
    deleteResearchTag (tagId) {
      if (this.tagEditMode) {
        this.removeResearchTag(tagId).then(() => this.getResearchTags(this.$route.params.id))
      }
    },
    deleteArticle () {
      if (this.deleteConfirmation === 'CONFIRM DELETE') {
        this.isDeleting = true
        this.softDeleteArticle({ articleId: this.GET_ARTICLE.id }).then((result) => {
          if (result.success) {
            this.$router.push({ name: 'route.dashboard.article' })
          }
        })
      }
    },
    cancelDelete () {
      this.deleteConfirmation = ''
      this.deleteMode = false
    },
    fileAdded (file) {
      this.form.thumbnail = file
      // this.warning.thumbnail = ''
    },
    fileRemoved () {
      this.form.thumbnail = ''
      // this.warning.thumbnail = 'This field is required'
    },
    getImage (path) {
      // var newPath = path.replace('public/', '')
      return process.env.VUE_APP_IMAGE_URL + '/' + path
    },
    toggleEdit () {
      this.editMode = !this.editMode
      this.btnEditLabel = this.editMode ? 'Cancel' : 'Edit'
      if (!this.editMode) {
        this.getArticleById({ id: this.$route.params.id })
      }
    },
    processValue (value, target) {
      if (target === 'short_description') {
        if (value.trim().length === 0) {
          this.GET_ARTICLE[target] = ''
          this.warning[target] = 'Please input some text in first content.'
          return
        } else {
          this.warning[target] = ''
          var trimmedValue = value.trim()
          this.GET_ARTICLE[target] = trimmedValue.slice(0, 240)
          return
        }
      }
      if (value === '') {
        this.warning[target] = 'This field is required'
      } else {
        if (this.warning[target] !== '') {
          this.warning[target] = ''
        }
        if (target === 'article_category_id') {
          this.GET_ARTICLE[target] = value.id
        } else if (target === 'published_at') {
          this.GET_ARTICLE[target] = this.moment(value).format('YYYY-MM-DD')
        } else {
          this.GET_ARTICLE[target] = value
        }
      }
    },
    validate () {
      var validation = true
      Object.keys(this.warning).forEach(val => {
        if (this.GET_ARTICLE[val] === '') {
          validation = false
          this.warning[val] = 'This field is required'
        }
      })
      return validation
    },
    hasNewThumbnail () {
      return this.form.thumbnail !== ''
    },
    uploadNewThumbnail () {
      var formData = new FormData()
      formData.append('newThumbnail', this.form.thumbnail)
      formData.append('id', this.GET_ARTICLE.id)
      this.updateThumbnail({ form: formData })
    },
    submitForm () {
      if (this.validate()) {
        this.commit()
      }
    },
    commit () {
      this.storeError = false

      var payload = null
      if (this.form.thumbnail !== '') {
        // var formData = new FormData()
        // formData.append('newThumbnail', this.form.thumbnail)
        // Object.keys(this.GET_ARTICLE).forEach(val => {
        //   if (val !== 'thumbnail') {
        //     formData.append(val, this.GET_ARTICLE[val])
        //   }
        // })
        // payload = formData
      } else {
        payload = this.GET_ARTICLE
      }

      this.updateArticle({ form: payload }).then((data) => {
        if (data.data !== null) {
          this.toggleEdit()
          this.showModal = true
        }
      }).catch(err => {
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    closeModal () {
      this.getArticleById({ id: this.$route.params.id })
      this.showModal = false
    },
    isTagIncluded (tagId) {
      for (var x = 0; x < this.GET_RESEARCH_TAGS.length; x++) {
        if (this.GET_RESEARCH_TAGS[x].tag_id === tagId) {
          return true
        }
      }
      return false
    }
  },
  created () {
    this.getAllCategories()
    this.getAllTags()
    this.getResearchTags(this.$route.params.id)
    this.getArticleById({ id: this.$route.params.id })
    request().get('/v1/article/analytic/' + this.$route.params.id).then((result) => {
      // console.log(result.data.data)
      if (result.data !== null) {
        this.analytic = result.data.data
      }
      this.analyticFetched = true
    }).catch((err) => {
      console.log(err)
    })
  }
}
</script>
