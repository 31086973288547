<template>
<div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <div class="container my-11 mx-auto bg-white">
          <div class="flex flex-col px-12 py-14" v-if="ARTICLE_SLIDER_READY">
            <div class="block">
              <span
                class="font-subtitle-1 text-subtitle-2 inline-block pb-3 text-gray-500"
              >
                <small>created at: </small> {{ getDate(GET_ARTICLE_SLIDER.created_at) }}
              </span>
              <ButtonSolidBox class="px-4 py-1 ml-4 rounded-full" @click="toggleEdit()">
              {{ btnEditLabel }}
              </ButtonSolidBox>
            </div>
            <h4
              v-show="!editMode"
              class="font-headline-5 text-headline-5 title-font text-gray-900 mt-4 mb-1"
            >
              {{ GET_ARTICLE_SLIDER.slider_name }}
            </h4>
            <InputTextBox
              v-show="editMode"
              placeholder="research title"
              :value="form.slider_name"
              @input="val => processValue(val,'slider_name')"
              :warning="warning.slider_name"
              warningClass="text-red-500"
            ></InputTextBox>
            <h5 class="font-subtitle-2 text-subtitle-1 mt-6 mb-1 text-gray-600" v-if="editMode"><small>Add upto 3 research in slider</small></h5>
            <InputTextBox
              v-if="editMode"
              placeholder="research title"
              @input="val => processValue(val,'article_title')"
              :warning="warning.article_title"
              warningClass="text-red-500"
            ></InputTextBox>

            <div class="my-2 w-full" v-if="editMode && GET_ARTICLES.length>0">
              <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Click to add research below.</small></h5>
              <div class="grid grid-cols-3 gap-4">
                <div class="bg-background-50 rounded shadow p-3" v-show="!isInSlider(item.id) && !isInNewList(item.id)" v-for="(item) in GET_ARTICLES" v-bind:key="item.id"  @click="pushArticle(item)">
                  <small>{{ item.article_title }}</small>
                  <hr class="border-gray-200" />
                  <small class="text-gray-500 mt-2">published at: {{ moment(item.published_at).format('DD/MM/YYYY') }}</small>
                  </div>
              </div>
            </div>
            <!-- <hr class="border-gray-200 my-3" />
            <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Selected article: </small></h5>
            <div class="my-2 w-full" v-if="newArticles.length>0">
              <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Click to remove article below.</small></h5>
              <div class="grid grid-cols-3 gap-4">
                <div class="bg-primary rounded shadow p-3 text-white" v-for="(item) in newArticles" v-bind:key="item.id" @click="removeNewArticle(item)">
                  <small>{{ item.article_title }}</small>
                  <hr class="border-gray-200" />
                  <small class="text-gray-500 mt-2">created at: {{ moment(item.created_at).format('DD/MM/YYYY') }}</small>
                </div>
              </div>
            </div> -->
            <!-- <hr> -->
            <div class="my-2 w-full" v-if="form.orderedArticles.length>0">
            <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Research(s):</small></h5>
            <draggable class="grid grid-cols-1 gap-4" v-model="form.orderedArticles" :sort="editMode" @end="(val) => orderChanged(val)">
              <div class="rounded shadow p-3  relative" v-for="(item) in form.orderedArticles" v-bind:key="item.id" :class="{  'bg-primary text-white': isItemNew(item), 'bg-red-50': willRemove(item)  }">
                <ButtonSolidBox class="absolute inset-y-0 mb-5 right-0 px-5 py-0 shadow mr-1 mt-1 rounded-full h-8 w-30 text-red" color="white" v-if="editMode && isItemNew(item)" @click="removeNewArticle(item)">
                  cancel
                </ButtonSolidBox>
                <ButtonSolidBox class="absolute inset-y-0 mb-5 right-0 px-5 py-0 shadow mr-1 mt-1 rounded-full h-8 w-30 text-red" color="white" v-if="editMode && !isItemNew(item)" @click="removeToggle(item)">
                  {{ isInDeleteList(item)?'cancel':'remove' }}
                </ButtonSolidBox>
                <small><b>{{ item.article_title }}</b></small>
                <br>
                <small>{{ item.short_description || item.first_summary }}</small>
                <br>
                <small class="text-gray-400">Published at: {{ getDate(item.published_at) }}</small>
              </div>
              <!-- <div class="rounded shadow p-3  relative bg-primary text-white" v-for="(item) in newArticles" v-bind:key="item.id">
                <ButtonSolidBox class="absolute inset-y-0 mb-5 right-0 px-5 py-0 shadow mr-1 mt-1 rounded-full h-8 w-30 text-red" color="white" v-if="editMode" @click="removeNewArticle(item)">
                  cancel
                </ButtonSolidBox>
                <small><b>{{ item.article_title }}</b></small>
                <br>
                <small>{{ item.short_description || item.first_summary }}</small>
                <br>
                <small class="text-gray-400">Published at: {{ getDate(item.published_at) }}</small>
              </div>
              <div class="rounded shadow p-3  relative" :class="[isInDeleteList(item.id)?'bg-red-50':'bg-background-50']" v-for="(item) in form.orderedArticles" v-bind:key="item.id">
                <ButtonSolidBox class="absolute inset-y-0 mb-5 right-0 px-5 py-0 shadow mr-1 mt-1 rounded-full h-8 w-30" color="red" v-if="editMode" @click="removeToggle(item.id)">
                  {{ isInDeleteList(item.id)?'cancel':'remove' }}
                </ButtonSolidBox>
                <small><b>{{ item.article.article_title }}</b></small>
                <br>
                <small>{{ item.short_description || item.first_summary }}</small>
                <br>
                <small class="text-gray-400">Published at: {{ getDate(item.article.published_at) }}</small>
              </div> -->
            </draggable>
          </div>
          </div>
          <div class="mt-1 w-full px-12 pb-5">
            <Loading :show="!ARTICLE_SLIDER_READY" />
            <Alert relative normal v-show="storeError">Failed to update.</Alert>
            <ButtonSolidBox class="px-12" v-show="editMode && ARTICLE_SLIDER_READY" @click="submitForm()">
              Save
            </ButtonSolidBox>
          </div>
        </div>
        <Modal name="Article" :show.sync="showModal"  strict-close>
          <div class="flex flex-col align-items-center w-full p-12">
            <div class="mx-auto my-8 text-center">
              <svg
                class="block mx-auto"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  fill="white"
                  fill-opacity="0.12"
                />
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  fill="#48D5D1"
                  fill-opacity="0.12"
                />
                <path
                  d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                  stroke="#48D5D1"
                  stroke-width="1.5"
                />
                <path
                  d="M46.334 26.0133L28.706 43.6414L17.666 32.6014L23.028 27.2395L28.706 32.9174L32 29.6234L40.972 20.6514L46.334 26.0133Z"
                  fill="#48D5D1"
                />
              </svg>
              <div class="block mt-12 font-headline-6 text-headline-6 text-gray">
                Slider has been updated!
              </div>
              <div
                class="block font-body-1 text-body-1 text-gray-500 max-w-sm mb-5"
              >
                Slider has been updated successfully.
              </div>
              <ButtonSolidBox v-show="ARTICLE_SLIDER_READY" class="px-8" @click="closeModal()">
                Ok
              </ButtonSolidBox>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<style scoped>
 </style>

<script>
import draggable from 'vuedraggable'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
// import InputDropdown from '@/components/Inputs/InputDropdown.vue'
// import InputImage from '@/components/Inputs/InputImage.vue'
// import InputAdvancedTextArea from '@/components/Inputs/InputAdvancedTextArea.vue'
import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Modal from '@/components/Modal.vue'
import { createNamespacedHelpers } from 'vuex'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'

const articleSlider = createNamespacedHelpers('articleSlider')
const article = createNamespacedHelpers('article')

export default {
  components: {
    ButtonSolidBox,
    TheSideNavigationBar,
    TheContentTopNavigationBar,
    InputTextBox,
    Loading,
    draggable,
    Alert,
    Modal
    // InputDropdown,
    // InputImage,
    // InputAdvancedTextArea
  },
  data () {
    return {
      queue: [],
      fetching: false,
      showModal: false,
      btnEditLabel: 'Edit',
      editMode: false,
      storeError: false,
      newArticles: [],
      form: {
        id: '',
        slider_name: '',
        orderedArticles: [],
        delete: [],
        add: []
      },
      warning: {
        slider_name: ''
      }
    }
  },
  computed: {
    ...articleSlider.mapGetters(['ARTICLE_SLIDER_READY', 'GET_ARTICLE_SLIDER']),
    ...article.mapGetters(['ARTICLE_READY', 'GET_ARTICLES'])
  },
  methods: {
    ...articleSlider.mapActions(['getSliderById', 'updateSlider']),
    ...article.mapActions(['findArticles', 'createInit']),
    getDate (dateString) {
      return this.moment(dateString).format('D/MM/YYYY')
    },
    isItemNew (item) {
      if (item.tag !== undefined && item.tag === 'NEW') {
        return true
      }
      return false
    },
    willRemove (item) {
      if (item.tag !== undefined && item.tag === 'DELETE') {
        return true
      }
      return false
    },
    isInDeleteList (item) {
      if (item.tag !== undefined && item.tag === 'DELETE') {
        return true
      }
      return false
    },
    isInAddList (id) {
      return this.form.add.includes(id)
    },
    isInSlider (id) {
      for (var x = 0; x < this.form.orderedArticles.length; x++) {
        if (this.form.orderedArticles[x].id === id) {
          return true
        }
      }
      return false
    },
    isInNewList (id) {
      for (var x = 0; x < this.newArticles.length; x++) {
        if (this.newArticles[x].id === id) {
          return true
        }
      }
      return false
    },
    pushArticle (item) {
      const data = Object.assign({}, item)
      data.tag = 'NEW'
      // this.newArticles.push(item)
      this.form.orderedArticles.unshift(data)
      // this.form.add.push(item.id)
    },
    removeNewArticle (item) {
      var index = this.form.orderedArticles.findIndex(val => val.id === item.id)
      // this.newArticles.splice(index, 1)
      this.form.orderedArticles.splice(index, 1)
      // this.form.add.splice(index, 1)
    },
    removeToggle (item) {
      console.log(item)
      var index = this.form.orderedArticles.findIndex(val => val.id === item.id)
      console.log(index)
      if (this.isInDeleteList(item)) {
        this.$set(this.form.orderedArticles[index], 'tag', undefined)
      } else {
        console.log(this.form.orderedArticles[index])
        this.$set(this.form.orderedArticles[index], 'tag', 'DELETE')
      }
    },
    // addToggle (itemId) {
    //   if (this.isInAddList(itemId)) {
    //     var index = this.form.add.sindIndex(id => id === itemId)
    //     this.form.add.splice(index, 1)
    //   } else {
    //     this.form.add.sush(itemId)
    //   }
    // },
    getImage (path) {
      var newPath = path.replace('public/', '')
      return process.env.VUE_APP_API_URL + '/' + newPath
    },
    toggleEdit () {
      this.editMode = !this.editMode
      this.btnEditLabel = this.editMode ? 'Cancel' : 'Edit'
      if (!this.editMode) {
        this.getSliderById({ id: this.$route.params.id })
      }
    },
    processValue (value, target) {
      if (value === '') {
        this.warning[target] = 'This field is required'
      } else {
        if (this.warning[target] !== '') {
          this.warning[target] = ''
        }

        if (target === 'article_title' && this.ARTICLE_SLIDER_READY && value.length > 3) {
        // console.log('find article with key: ' + value)
          if (this.fetching) {
            if (this.queue.length > 0) {
              this.queue[0] = { value: value, target: target }
            } else {
              this.queue.push({ value: value, target: target })
            }
            return
          }
          this.fetching = true
          this.findArticles({ data: { find: value } }).then(() => {
            this.fetching = false
            if (this.queue.length > 0) {
              this.processValue(this.queue[0].value, this.queue[0].target)
              this.queue.pop()
            }
          })
          return
        }

        this.form[target] = value
      }
    },
    validate () {
      var validation = true
      Object.keys(this.warning).forEach(val => {
        if (this.form.slider_name === '') {
          validation = false
          this.warning[val] = 'This field is required'
        }
      })
      return validation
    },
    submitForm () {
      if (this.validate()) {
        this.commit()
      }
    },
    commit () {
      this.storeError = false
      this.updateSlider({ form: this.form }).then((data) => {
        if (data.data !== null) {
          this.toggleEdit()
          this.newArticles = []
          this.showModal = true
        }
      }).catch(err => {
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    closeModal () {
      this.getSliderById({ id: this.$route.params.id })
      this.showModal = false
    },
    orderChanged (val) {
      console.log('data')
      console.log(this.form.orderedArticles)
      console.log('element')
      console.log(val)
    }
  },
  created () {
    this.form.id = this.$route.params.id
    this.getSliderById({ id: this.$route.params.id }).then(result => {
      // console.log(result.data.metadata)
      this.form.slider_name = result.data.slider_name
      // this.form.orderedArticles = result.data.metadata
      result.data.metadata.forEach(item => {
        this.form.orderedArticles.push(item.article)
      })
    })
  }
}
</script>
