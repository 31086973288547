<template>
  <div class="editor border rounded border-gray-soft mt-5">
    <VueEditor :editorOptions="editorSettings" :customModules="customModulesForEditor" :ref="refId" @input="(_) => inputUpdated()" :placeholder="placeholder" v-model="content" />
    <div class="font-caption text-caption px-4 py-2 w-full" :class="warningClass">
      {{warning}}
    </div>
  </div>
</template>

<script>
import { VueEditor, Quill } from 'vue2-editor'
import QuotesToolbar from './QuotesToolbar'
const Parchment = Quill.import('parchment')
console.log(Parchment.Scope)
const configInline = { scope: Parchment.Scope.INLINE, whitelist: ['quotes-text', 'quotes-image', 'quotes-devider'] }
const AcceptedPredictionClassInline = new Parchment.Attributor.Class('accepted', 'ql', configInline)
console.log(Parchment.Attributor.Class)
Quill.register(AcceptedPredictionClassInline)
Quill.register({ 'attributors/class': 'AcceptedPredictionClassInline' })
export default {
  components: {
    VueEditor,
    // eslint-disable-next-line vue/no-unused-components
    QuotesToolbar
  },
  props: {
    placeholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    warning: {
      type: String,
      default: ''
    },
    warningClass: {
      type: String,
      default: 'text-gray-500'
    },
    value: {
      type: String,
      default: ''
    },
    refId: {
      type: String,
      default: 'vueEditor'
    }
  },
  data () {
    return {
      content: '',
      quillContent: '',
      ready: false,
      customModulesForEditor: [
        { alias: 'quotes', module: QuotesToolbar }
      ],
      editorSettings: {
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ align: [] }],
              ['code-block'], // blockquote
              [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
              [
                {
                  color: [
                    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
                    '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
                    '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
                    '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
                    '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
                    '#59459B', '#474BB3', '#4362A8', '#4198D3', '#4F4D4D'
                  ]
                }, { background: [] }],
              ['link', 'image', 'video'],
              ['quotes']
            ],
            handlers: {
              quotes: (value) => {
                this.$refs[this.refId].quill.focus()
                var caretPosition = this.$refs[this.refId].quill.getSelection(true)
                console.log(caretPosition)
                var Delta = Quill.import('delta')
                var quoteText = '  Insert your quotes here...  '
                if (caretPosition.length > 0) {
                  quoteText = this.$refs[this.refId].quill.getText(caretPosition.index, caretPosition.length)
                  this.$refs[this.refId].quill.deleteText(caretPosition.index, caretPosition.length)
                  var delta = new Delta().retain(caretPosition.index).insert(quoteText, { italic: true, accepted: 'quotes-text' }).insert('Algo  Research', { bold: true, accepted: 'quotes-devider' }).insert('Algo Research', { italic: true, accepted: 'quotes-image' }).insert('', {})
                  this.$refs[this.refId].quill.updateContents(delta)
                }
                // var refNode = this.$refs[this.refId].quill.getContents()
                // console.log(this.$refs[this.refId].quill.getContents(caretPosition.index, caretPosition.length))
                // this.$refs[this.refId].quill.addContainer('ql-quotes-container')
                // var el = this.$refs[this.refId].quill.clipboard.dangerouslyPasteHTML(caretPosition.index, '<br><div><div><p style="background:#000000">Please insert your quotes here</p></div><hr><div><img src="https://algovision.s3.ap-southeast-3.amazonaws.com/algo-logo.svg" class="algo-quote-img"></div></div><br>', 'silent')
                // console.log(el)
                // this.$refs[this.refId].quill.insertEmbed(caretPosition.index, 'quotebox', '')
              }
            }
          }
          // clipboard: {
          //   matchers: [
          //     [
          //       'ql-quotes-devider', (node, delta) => {
          //         console.log(node)
          //         console.log(delta)
          //         var Delta = Quill.import('delta')
          //         return delta.compose(new Delta().retain(delta.length(), { accepted: 'quotes-devider' }))
          //       }
          //     ]
          //   ]
          // }
          // clipboard: {
          //   mathcers: [
          //     ['div', ]
          //   ]
          // }
        }
      }
    }
  },
  watch: {
    content: function (newVal) {
      if (this.ready) {
        // console.log(newVal)
        this.$emit('content', newVal)
      }
    }
  },
  created () {
    console.log(Quill.import('formats'))
    // const Inline = Quill.import('blots/inline')

    // Inline.blotName = 'span_quotes'
    // Inline.className = 'ql-quotes-image'
    // Inline.tagName = 'SPAN'

    // Quill.register({
    //   'formats/span_quotes': Inline
    // })
  },
  mounted () {
    console.log(this.$refs[this.refId].quill)
    // this.$refs[this.refId].quill.setContents()
    // this.content = this.value
    this.$refs[this.refId].quill.root.innerHTML = this.value
    this.ready = true
  },
  methods: {
    inputUpdated () {
      // console.log(this.$refs)
      // console.log(this.$refs[this.refId].quill.getText())
      this.$emit('text-only', this.$refs[this.refId].quill.getText())
    }
  }
}
</script>

<style lang="css">
@import '~vue2-editor/dist/vue2-editor.css';

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import './../../assets//css/quill-extended.css';

.editor .ql-toolbar.ql-snow,
.editor .ql-container.ql-snow {
  @apply border-gray-100;
}
.editor .ql-toolbar.ql-snow {
  @apply rounded-t bg-background-50;
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: rgb(240, 240, 240);
}
.editor .ql-container.ql-snow {
  @apply rounded-b;
  z-index: 0;
}
@media only screen and (min-width: 0px) {
  .ql-video {
    width: calc(100vw*0.8);
    height: calc(100vw*0.5);
  }
  .ql-quotes-text {
    display: block !important;
    position: relative;
    background-color: #E8F0F8;
    margin-top: 32px;
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom:16px;
    /* border-bottom: 1px solid black; */
    text-align:center;
    font-weight: bold;
    font-size: 24px;
  }
  .ql-quotes-text:has( > p) {
    position: relative;
  }
  .ql-quotes-text:before {
    content: url('https://algovision.s3.ap-southeast-3.amazonaws.com/algo-quote.svg');
    position: absolute;
    top: calc(18px + 5px);
    transform: translateX(-110%);
  }
  .ql-quotes-text:after {
    content: url('https://algovision.s3.ap-southeast-3.amazonaws.com/quotes-rotated.svg');
    position: absolute;
    bottom: calc(18px + 5px);
    transform: translateX(20%);
  }
  .ql-quotes-image {
    display: block !important;
    background-color: #E8F0F8;
    margin-bottom: 32px;
    padding-top: 16px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
    font-size:0px;
    text-align:center;
    margin: auto;
  }
  .ql-quotes-devider {
    display: block !important;
    height:1px;
    color: #E8F0F8;
    font-size: 0px;
    border-left: 32px solid #E8F0F8;
    border-right: 32px solid #E8F0F8;
    background-color:#000000;
    position: relative;
  }
  .ql-quotes-image:after {
    content: url('https://algovision.s3.ap-southeast-3.amazonaws.com/algo-logo.svg');
  }
  .ql-editor .ql-align-justify {
    font-family:
      'Merriweather', arial, sans-serif !important
  }
  .ql-editor {
    font-family:
      'Merriweather', arial, sans-serif !important
  }
  .ql-editor .ql-align-left {
    font-family:
      'Merriweather', arial, sans-serif !important
  }
  .ql-editor .ql-align-right {
    font-family:
      'Merriweather', arial, sans-serif !important
  }
  .ql-editor .ql-align-center {
    font-family:
      'Merriweather', arial, sans-serif !important
  }
  .ql-quotes::after {
    content: url('/icon/quotes-sm.svg');
    width: 22px !important;
    height: 22px !important;
  }
  .ql-quotes{
    display: inline-block;
  }
  .ql-quote-container {
    background-color: #E8F0F8;
    text-align: center;
  }
}
/* small */
@media only screen and (min-width: 576px) {
}
/* medium */
@media only screen and (min-width: 768px) {
  .ql-video {
    width: calc(100vw*0.75);
    height: calc(100vw*0.45);
  }
}
/* large */
@media only screen and (min-width: 992px) {
  .ql-video {
    width: calc(100vw*0.5);
    height: calc(100vw*0.25);
  }
}
/* extra large */
@media only screen and (min-width: 1200px) {
  .ql-video {
    width: calc(100vw*0.4);
    height: calc(100vw*0.2);
  }
}
/* extra extra large */
@media only screen and (min-width: 1400px) {
}</style>
